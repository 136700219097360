import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { selectProductCategories, selectLoaded, selectOtherCategories, selectImageUrl, selectImageLoaded } from '../selectors/product-categories.selector';
import { productCategoriesActions } from '../actions/product-categories.actions';
import { ProductCategory } from '../models';

@Injectable({
    providedIn: 'root',
})
export class ProductCategoriesFacade {
    private readonly store = inject(Store);

    loaded$ = this.store.select(selectLoaded);
    productCategories$ = this.store.select(selectProductCategories).pipe(filter((data): data is ProductCategory[] => data !== null));
    otherCategories$ = this.store.select(selectOtherCategories).pipe(filter((data): data is ProductCategory[] => data !== null));

    imageUrl$ = (subcode: string) => this.store.select(selectImageUrl(subcode));
    imageLoaded$ = (subcode: string) => this.store.select(selectImageLoaded(subcode));

    getProductCategories(): void {
        this.store.dispatch(productCategoriesActions.getProductCategories());
    }

    getCategoryImage(subcode: string): void {
        this.store.dispatch(productCategoriesActions.getCategoryImage({ subcode }));
    }

    clear(): void {
        this.store.dispatch(productCategoriesActions.clear());
    }
}
