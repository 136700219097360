import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ProductCategories } from '../models/product-categories';

export const productCategoriesActions = createActionGroup({
    source: 'Product Categories',
    events: {
        'Get Product Categories': emptyProps(),
        'Get Product Categories Success': props<ProductCategories>(),
        'Get Product Categories Error': emptyProps(),

        'Get Category Image': props<{ subcode: string }>(),
        'Get Category Image Success': props<{ subcode: string; blob: Blob }>(),
        'Get Category Image Error': props<{ subcode: string }>(),

        Clear: emptyProps(),
    },
});
