import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ApiResponse } from '@app/shared/models';
import { map } from 'rxjs';
import { ProductCategoriesDto } from '../models/product-categories.dto';
import { ProductCategories } from '../models/product-categories';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    private readonly httpClient = inject(HttpClient);

    getProductCategories() {
        return this.httpClient.get<ApiResponse<ProductCategoriesDto>>(`api/v1/categories/items`).pipe(map((response) => new ProductCategories(response.data)));
    }

    getCategoryImage(subcode: string) {
        return this.httpClient.addResponseCaching().get(`api/v1/categories/items/${subcode}`, { responseType: 'blob' });
    }
}
