import { createReducer, on } from '@ngrx/store';
import { productCategoriesActions } from '../actions/product-categories.actions';
import { ProductCategory } from '../models';

interface ImageEntry {
    loaded: null | boolean;
    blob: null | Blob;
}
export interface ProductCategoriesState {
    loaded: boolean | null;
    categories: ProductCategory[] | null;
    others: ProductCategory[] | null;
    images: Map<string, ImageEntry>;
}

export const initialState: ProductCategoriesState = {
    loaded: null,
    categories: null,
    others: null,
    images: new Map(),
};

export const productCategoriesReducer = createReducer(
    initialState,
    on(
        productCategoriesActions.getProductCategoriesSuccess,
        (state, payload): ProductCategoriesState => ({
            ...state,
            loaded: true,
            categories: payload.categories,
            others: payload.others,
        }),
    ),
    on(
        productCategoriesActions.getProductCategoriesError,
        (state): ProductCategoriesState => ({
            ...state,
            loaded: false,
        }),
    ),

    on(productCategoriesActions.getCategoryImage, (state, payload): ProductCategoriesState => {
        const images = new Map(state.images);

        images.set(payload.subcode, { blob: null, loaded: null });

        return {
            ...state,
            images,
        };
    }),

    on(productCategoriesActions.getCategoryImageSuccess, (state, payload): ProductCategoriesState => {
        const images = new Map(state.images);

        images.set(payload.subcode, { blob: payload.blob, loaded: true });

        return {
            ...state,
            images,
        };
    }),

    on(productCategoriesActions.getCategoryImageError, (state, payload): ProductCategoriesState => {
        const images = new Map(state.images);

        images.set(payload.subcode, { blob: null, loaded: false });

        return {
            ...state,
            images,
        };
    }),
    on(
        productCategoriesActions.clear,
        (): ProductCategoriesState => ({
            ...initialState,
        }),
    ),
);
