import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductCategoriesState } from '../reducers/product-categories.reducer';
import { PRODUCT_CATEGORIES_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<ProductCategoriesState>(PRODUCT_CATEGORIES_FEATURE_KEY);

const selectLoaded = createSelector(selectState, (state: ProductCategoriesState) => state.loaded);
const selectProductCategories = createSelector(selectState, (state: ProductCategoriesState) => state.categories);
const selectOtherCategories = createSelector(selectState, (state: ProductCategoriesState) => state.others);
const selectImageUrl = (subcode: string) =>
    createSelector(selectState, (state: ProductCategoriesState) => {
        const result = state.images.get(subcode);

        return result?.blob ? URL.createObjectURL(result.blob) : null;
    });

const selectImageLoaded = (subcode: string) =>
    createSelector(selectState, (state: ProductCategoriesState) => {
        const result = state.images.get(subcode);

        return result ? result.loaded : null;
    });

export { selectLoaded, selectProductCategories, selectOtherCategories, selectImageUrl, selectImageLoaded };
