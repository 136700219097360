import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of, catchError, map, switchMap, mergeMap } from 'rxjs';
import { HttpService } from '../services/http.service';
import { productCategoriesActions } from '../actions/product-categories.actions';

@Injectable()
export class ProductCategoriesEffects {
    private readonly actions$ = inject(Actions);
    private readonly httpService = inject(HttpService);

    getProductCategories$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(productCategoriesActions.getProductCategories),
            switchMap(() => {
                return this.httpService.getProductCategories().pipe(
                    map((res) => productCategoriesActions.getProductCategoriesSuccess(res)),
                    catchError(() => of(productCategoriesActions.getProductCategoriesError())),
                );
            }),
        );
    });

    getCategoryImage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(productCategoriesActions.getCategoryImage),
            mergeMap(({ subcode }) => {
                return this.httpService.getCategoryImage(subcode).pipe(
                    map((blob) => productCategoriesActions.getCategoryImageSuccess({ blob, subcode })),
                    catchError(() => of(productCategoriesActions.getCategoryImageError({ subcode }))),
                );
            }, 3),
        );
    });
}
