import { ProductCategoryDto } from './index';

export class ProductCategory {
    catalogueSubcode: string;
    catalogueCode: string;
    description: string;
    progressiveNr: number;

    constructor(productCategoryDto: ProductCategoryDto) {
        this.catalogueSubcode = productCategoryDto.catalogue_subcode;
        this.catalogueCode = productCategoryDto.catalogue_code;
        this.description = productCategoryDto.description;
        this.progressiveNr = productCategoryDto.progressive_nr;
    }
}
