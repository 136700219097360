import { ProductCategory } from './index';
import { ProductCategoriesDto } from './product-categories.dto';

export class ProductCategories {
    categories: ProductCategory[];
    others: ProductCategory[];

    constructor(dto: ProductCategoriesDto) {
        this.categories = dto.categories.map((item) => new ProductCategory(item));
        this.others = dto.others.map((item) => new ProductCategory(item));
    }
}
